import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { AllImage } from '../../utils/graphql';
import DiscoverLink from '../buttons/DiscoverLink';
import * as Colors from '../Colors';
import { GatsbyImage } from 'gatsby-plugin-image';
import Certification from './Certification';

const Training: React.VoidFunctionComponent = () => {
  const {
    allFile: { nodes: teamMemberImages },
  }: {
    allFile: AllImage;
  } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            sourceInstanceName: { eq: "images" }
            relativeDirectory: { eq: "team" }
            name: { eq: "fabien-crassat" }
          }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 65
                height: 65
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER, fit: COVER }
              )
            }
            name
          }
        }
      }
    `,
  );
  const { t } = useTranslation('academy');
  const indicatorDisplay = (indicator: string) => (
    <div className="mt-4 flex w-52 border-l-4 px-4">
      <div className="pr-4 text-6xl">{t(`training.indicators.${indicator}.number`)}</div>
      <div className="m-auto">
        <div className="my-1">{t(`training.indicators.${indicator}.title`)}</div>
        <div className="my-1">{t(`training.indicators.${indicator}.detail`)}</div>
      </div>
    </div>
  );
  const trainingDayDisplay = (day: string) => (
    <div className="m-2 border border-black">
      <div className="bg-black p-1 text-center text-white">
        {t(`training.details.days.${day}.title`)}
      </div>
      <ul className="my-2 ml-7 mr-2 list-disc">
        <li>{t(`training.details.days.${day}.text1`)}</li>
        <li>{t(`training.details.days.${day}.text2`)}</li>
        <li>{t(`training.details.days.${day}.text3`)}</li>
        <li>{t(`training.details.days.${day}.text4`)}</li>
      </ul>
    </div>
  );

  return (
    <>
      <section id="training">
        <div className={`lg:h-screen ${Colors.WHITE_ON_BLUE}`}>
          <div className="container flex h-[97%] max-w-224 flex-col justify-center px-4 py-8 sm:px-8">
            <h2 className="mb-6 text-left text-3xl font-bold leading-snug sm:text-4xl">
              {t('training.title')}
            </h2>
            <p className="mb-6">{t('training.description')}</p>
            <div className="flex flex-wrap justify-evenly">
              {indicatorDisplay('duration')}
              {indicatorDisplay('price')}
              {indicatorDisplay('satisfaction')}
              {indicatorDisplay('evaluation')}
            </div>
          </div>
          <div className="h-[3%] pr-2 text-end text-xs">
            {t('training.indicators.no-data-available')}
          </div>
        </div>
      </section>
      <section id="training-details">
        <div className={`lg:h-full ${Colors.BLACK_ON_WHITE}`}>
          <div className="container flex h-full max-w-224 flex-col justify-center px-4 py-8 sm:px-8">
            <h3 className="mb-6 text-left text-3xl font-bold leading-snug sm:text-4xl">
              {t('training.details.title')}
            </h3>
            <p className="m-auto">{t('training.details.text')}</p>
            <div className="m-auto">
              <div className="mt-6 flex flex-shrink-[1.5]">
                <div className="w-8 border-r-2 border-r-reacteev-blue">
                  <div className="w-fit -translate-x-11 translate-y-12 -rotate-90 text-lg font-bold uppercase text-reacteev-blue">
                    {t('training.details.objectives.title')}
                  </div>
                </div>
                <ul className="ml-8 list-disc">
                  <li className="mb-2">{t('training.details.objectives.text1')}</li>
                  <li className="mb-2">{t('training.details.objectives.text2')}</li>
                  <li className="mb-2">{t('training.details.objectives.text3')}</li>
                  <li className="mb-2">{t('training.details.objectives.text4')}</li>
                  <li className="mb-2">{t('training.details.objectives.text5')}</li>
                  <li className="mb-2">{t('training.details.objectives.text6')}</li>
                </ul>
              </div>
              <div className="mt-6">
                <div className="flex flex-wrap justify-center md:flex-nowrap">
                  {trainingDayDisplay('day1')}
                  {trainingDayDisplay('day2')}
                  {trainingDayDisplay('day3')}
                </div>
                <div className="px-12 pt-6 text-center">
                  <div>
                    <DiscoverLink
                      text={t('training.details.download-catalog')}
                      href="/academy/programme_projet_informatique_organisation_et_cahier_des_charges_V4.pdf"
                      pseudoClass={Colors.BLACK_ON_YELLOW_PSEUDO_CLASS}
                      svgClass="rotate-90"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="trainer-details">
        <div className={`${Colors.BLACK_ON_YELLOW}`}>
          <div className="container flex h-full max-w-224 flex-col justify-center px-4 py-8 sm:px-8">
            <h3 className="text-left text-4xl font-semibold">
              {t('training.details.trainer.title')}
            </h3>
            <div className="mt-4 flex">
              <GatsbyImage
                image={teamMemberImages[0].childImageSharp.gatsbyImageData}
                className="mr-4 select-none rounded-full"
                title="Fabien Crassat"
                alt="Fabien Crassat"
                draggable={false}
              />
              <div>
                <div className="font-semibold">{t('training.details.trainer.name')}</div>
                <div>{t('training.details.trainer.job')}</div>
              </div>
            </div>
            <div className="mt-6">{t('training.details.trainer.description')}</div>
          </div>
        </div>
      </section>
      <section id="certified-trainings">
        <div className={`${Colors.BLACK_ON_YELLOW}`}>
          <div className="container flex h-full max-w-224 flex-col justify-center px-4 pb-8 sm:px-8">
            <h3 className="text-left text-4xl font-semibold">
              {t('training.details.certification.title')}
            </h3>
            <div className="my-8 w-full lg:pr-12">
              <Certification />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Training;
