import React from 'react';
import { useTranslation } from 'react-i18next';
import DiscoverLink from '../buttons/DiscoverLink';
import * as Colors from '../Colors';

const LinkedDocuments: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('academy');
  const documentDisplay = (document: string, link: string) => (
    <div className="w-full p-4 md:w-1/2">
      <h3 className="text-left text-3xl font-bold">{t(`documents.${document}`)}</h3>
      <DiscoverLink
        text={t('documents.download')}
        href={link}
        pseudoClass={Colors.WHITE_ON_BLACK_PSEUDO_CLASS}
        svgClass="rotate-90"
      />
    </div>
  );

  return (
    <section id="documents">
      <div className={`${Colors.WHITE_ON_BLACK}`}>
        <div className="container flex h-full max-w-224 flex-col justify-center px-4 py-8 sm:px-8">
          <h2 className="mb-6 text-left text-3xl font-bold leading-snug sm:text-4xl">
            {t('documents.title')}
          </h2>
          <div className="flex flex-wrap">
            {documentDisplay('rules-of-procedure', '/academy/reglement_interieur.pdf')}
            {documentDisplay('connection-assistance', '/academy/aide_à_la_connexion.pdf')}
            {documentDisplay('sales-conditions', '/academy/conditions_generales_de_vente_V3.2.pdf')}
            {documentDisplay(
              'privacy-policy',
              '/academy/politique_confidentialité_gestion_proj_info_V2.pdf',
            )}
            {documentDisplay('financing-booklet', '/academy/livret_financement_formation_V1.pdf')}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LinkedDocuments;
